import { EnviromentVariant, Domain } from '@/types/ApiService';

export const DOMAINS: Record<EnviromentVariant, Record<string, Domain>> = {
    // Локальные ветки
    dev: {
        main: {
            secure: true,
            domain: 'test.api.prohome.kay-com.net',
            prefix: 'api',
        },
    },
    // Ветка dev
    test: {
        main: {
            secure: true,
            domain: 'test.api.prohome.kay-com.net',
            prefix: 'api',
        },
    },
    // Препрод ветка prod
    preprod: {
        main: {
            secure: true,
            domain: 'preprod.api.prohome.kay-com.net',
            prefix: 'api',
        },
    },
    // Прод ветка prod
    prod: {
        main: {
            secure: true,
            domain: 'api.prohome.ru',
            prefix: 'api',
        },
    },
};
